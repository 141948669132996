// {
//   title: "Something",
//   link: "",
//   author: "Something",
//   tags: ["Something"]
// };

const ARTICLES = [
  {
    title: "7 Habits of Highly Effective People",
    link: "https://www.goodreads.com/book/show/36072.The_7_Habits_of_Highly_Effective_People",
    author: "Stephen R. Covey",
    tags: ["Self Help", "Productivity"]
  },
  {
    title: "33 Strategies of War",
    link: "https://www.goodreads.com/book/show/35289.The_33_Strategies_of_War",
    author: "Robert Greene",
    tags: ["Self Help", "Psychology", "Society"]
  },
  {
    title: "48 Laws of Power",
    link: "https://www.goodreads.com/book/show/1303.The_48_Laws_of_Power",
    author: "Robert Greene",
    tags: ["Self Help", "Psychology", "Society"]
  },
  {
    title: "1984",
    link: "https://www.goodreads.com/book/show/61439040-1984",
    author: "George Orwell",
    tags: ["Fiction", "Politics", "Society"]
  },
  {
    title: "Algorithms to Live by",
    link: "https://www.goodreads.com/book/show/25666050-algorithms-to-live-by",
    author: "Brian Christian, Tom Griffiths",
    tags: ["Psychology", "Self Help", "Technology"]
  },
  {
    title: "Art of Seduction",
    link: "https://www.goodreads.com/book/show/20995.The_Art_of_Seduction",
    author: "Robert Greene",
    tags: ["Self Help", "Psychology", "Society"]
  },
  {
    title: "Art of War",
    link: "https://www.goodreads.com/book/show/10534.The_Art_of_War",
    author: "Sun Tzu",
    tags: ["Philosophy", "Politics", "Psychology"]
  },
  {
    title: "Deep Work",
    link: "https://www.goodreads.com/book/show/25744928-deep-work",
    author: "Cal Newport",
    tags: ["Creativity", "Advice", "Productivity"]
  },
  {
    title: "Discourses and Selected Writings",
    link: "https://www.goodreads.com/book/show/4143812-discourses-and-selected-writings",
    author: "Epictetus",
    tags: ["Philosophy", "Self Help", "Stoicism"]
  },
  {
    title: "How to Win Friends and Influence People",
    link: "https://www.goodreads.com/book/show/4865.How_to_Win_Friends_and_Influence_People",
    author: "Dale Carnegie",
    tags: ["Self Help"]
  },
  {
    title: "Letters from a Stoic",
    link: "https://www.goodreads.com/book/show/97411.Letters_from_a_Stoic",
    author: "Seneca",
    tags: ["Philosophy", "Self Help", "Stoicism"]
  },
  {
    title: "Man's search for meaning",
    link: "https://www.goodreads.com/book/show/4069.Man_s_Search_for_Meaning",
    author: "Victor Frankl",
    tags: ["Biography", "Self Help"]
  },
  {
    title: "Mastery",
    link: "https://www.goodreads.com/book/show/13589182-mastery",
    author: "Robert Greene",
    tags: ["Self Help", "Creativity"]
  },
  {
    title: "Meditations",
    link: "https://www.goodreads.com/book/show/30659.Meditations",
    author: "Marcus Aurelius",
    tags: ["Philosophy", "Self Help", "Stoicism"]
  },
  {
    title: "Pimp",
    link: "https://www.goodreads.com/book/show/108713.Pimp",
    author: "Iceberg Slim",
    tags: ["Biography", "Psychology"]
  },
  {
    title: "Poor Charlie's Almanack",
    link: "https://www.goodreads.com/book/show/944652.Poor_Charlie_s_Almanack",
    author: "Charles  Munger",
    tags: ["Wisdom", "Life"]
  },
  {
    title: "Psychology of Money",
    link: "https://www.goodreads.com/book/show/41881472-the-psychology-of-money",
    author: "Morgan Housel",
    tags: ["Success", "Psychology"]
  },
  {
    title: "Steal like an Artist",
    link: "https://www.goodreads.com/book/show/13099738-steal-like-an-artist",
    author: "Austin Kleon",
    tags: ["Creativity", "Advice"]
  },
  {
    title: "Steve Jobs",
    link: "https://www.goodreads.com/book/show/11084145-steve-jobs",
    author: "Walter Isaacson",
    tags: ["Biography"]
  },
  {
    title: "Talking to strangers",
    link: "https://www.goodreads.com/book/show/43848929-talking-to-strangers",
    author: "Malcolm Gladwell",
    tags: ["Self Help", "Psychology", "Society"]
  },
  {
    title: "The Autobiography of Benjamin Franklin",
    link: "https://www.goodreads.com/book/show/52309.The_Autobiography_of_Benjamin_Franklin",
    author: "Benjamin Franklin",
    tags: ["Biography", "Self Help"]
  },
  {
    title: "The Fountainhead",
    link: "https://www.goodreads.com/book/show/2122.The_Fountainhead",
    author: "Ayn Rand",
    tags: ["Fiction", "Philosophy"]
  },
  {
    title: "The Prince",
    link: "https://www.goodreads.com/book/show/28862.The_Prince",
    author: "Niccolò Machiavelli",
    tags: ["Politics", "Psychology"]
  },
  {
    title: "The Prophet",
    link: "https://www.goodreads.com/book/show/2547.The_Prophet",
    author: "Khalil Gibran",
    tags: ["Self Help", "Philosophy"]
  },
  {
    title: "To Shake the Sleeping Self",
    link: "https://www.goodreads.com/book/show/35356383-to-shake-the-sleeping-self",
    author: "Jedidiah Jenkins",
    tags: ["Travel", "Biography"]
  },
  {
    title: "Tools of Titans",
    link: "https://www.goodreads.com/book/show/31823677-tools-of-titans",
    author: "Tim Ferriss",
    tags: ["Productivity", "Inspiration", "Time Management", "Success"]
  },
  {
    title: "War of Art",
    link: "https://www.goodreads.com/book/show/1319.The_War_of_Art",
    author: "Steven Pressfield",
    tags: ["Self Help", "Creativity"]
  },
  {
    title: "What I Talk About When I Talk About Running",
    link: "https://www.goodreads.com/book/show/2195464.What_I_Talk_About_When_I_Talk_About_Running",
    author: "Haruki Murakami",
    tags: ["Biography", "Sports"]
  },
  {
    title: "Zen and the Art of Motorcycle Maintenance",
    link: "https://www.goodreads.com/book/show/629.Zen_and_the_Art_of_Motorcycle_Maintenance",
    author: "Robert M Pirsig",
    tags: ["Philosophy", "Self Help", "Travel"]
  }
];

// {
//   title: "Something",
//   link: "",
//   author: "Something",
//   tags: ["Something"]
// };

const MOVIES = [
  { title: "Rocky", link: "something", tags: [] },
  { title: "The Dark Knight", link: "something", tags: [] },
  { title: "Harry Potter", link: "something", tags: [] },
  { title: "The Godfather", link: "something", tags: [] },
  { title: "Star Wars series", link: "something", tags: [] },
  { title: "Star Trek series", link: "something", tags: [] },
  { title: "The Lord of the Rings trilogy", link: "something", tags: [] },
  { title: "The Before trilogy", link: "something", tags: [] },
  { title: "Avatar", link: "something", tags: [] },
  { title: "About Time", link: "something", tags: [] },
  { title: "Masaan", link: "something", tags: [] },
  { title: "Sardar Udham", link: "something", tags: [] },
  { title: "3 Idiots", link: "something", tags: [] },
  { title: "Rabb da Radio series", link: "something", tags: [] },
  { title: "Punjab 1984", link: "something", tags: [] },
  { title: "La La Land", link: "something", tags: [] },
  { title: "Blue Valentine", link: "something", tags: [] },
  { title: "Schindler’s List", link: "something", tags: [] },
  { title: "The Shawshank Redemption", link: "something", tags: [] },
  { title: "Fight Club", link: "something", tags: [] },
  { title: "Goodfellas", link: "something", tags: [] },
  { title: "Se7en", link: "something", tags: [] },
  { title: "American History X", link: "something", tags: [] },
  { title: "Whiplash", link: "something", tags: [] },
  { title: "Inception", link: "something", tags: [] },
  { title: "The Lives of Others", link: "something", tags: [] },
  { title: "Avengers: End Game", link: "something", tags: [] },
  { title: "Spiderman: Into the Spiderverse", link: "something", tags: [] },
  { title: "Good Will Hunting", link: "something", tags: [] },
  { title: "The Hunt", link: "something", tags: [] },
  { title: "Reservoir Dogs", link: "something", tags: [] },
  { title: "Heat", link: "something", tags: [] },
  { title: "Hamilton", link: "something", tags: [] },
  { title: "A Separation", link: "something", tags: [] },
  { title: "Die Hard", link: "something", tags: [] },
  { title: "Green Book", link: "something", tags: [] },
  { title: "There will be blood", link: "something", tags: [] },
  { title: "A Beautiful Mind", link: "something", tags: [] },
  { title: "No Country for Old Men", link: "something", tags: [] },
  { title: "Kill Bill", link: "something", tags: [] },
  { title: "V for Vendetta", link: "something", tags: [] },
  { title: "Three billboards outside Ebbing, Missouri", link: "something", tags: [] },
  { title: "Warrior", link: "something", tags: [] },
  { title: "Spotlight", link: "something", tags: [] },
  { title: "The Post", link: "something", tags: [] },
  { title: "Rush", link: "something", tags: [] },
  { title: "Into the wild", link: "something", tags: [] },
  { title: "The Help", link: "something", tags: [] },
  { title: "12 Angry Men", link: "something", tags: [] },
  { title: "Fracture", link: "something", tags: [] },
  { title: "The matrix", link: "something", tags: [] }
];

module.exports = { ARTICLES };

