/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "components/Layout";
import SEO from "components/SEO";
import "./ideas.css";
import Tooltip from "components/Tooltip";
import { ARTICLES } from "content/ideas";

function BetterPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;

  useEffect(() => {
    const articlesFiltersMap = {};

    ARTICLES.forEach(article => {
      article.tags.forEach(tag => {
        articlesFiltersMap[tag] = 1;
      });
    });
  }, []);

  const items = ARTICLES;

  return (
    <Layout
      location={location}
      title={siteTitle}
      breadcrumbConfig={{
        url: "/ideas",
        content: (
          <Link to="/ideas">
            <h1 style={{ margin: `0rem` }}>100 Ideas</h1>
          </Link>
        )
      }}
    >
      <SEO title="100 Ideas" description="My favourite ideas, by people far better than me" />
      <div className="expand-info">
        A constantly evolving collection of 100 books that continue to shape my thinking. These
        books are selected for their high shelf-life and maximum return on investment.
        {/* INSPO: https://blog.acolyer.org/2018/02/23/as-we-may-think/ */}
      </div>
      <ol className="better-ordered-list">
        {items.map(item => (
          <li key={Math.random()} className="better-links">
            {item.link.length > 0 ? (
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            ) : (
              <span>{item.title}</span>
            )}{" "}
            <span>{` - ${item.author}`}</span>
            {item.content &&
              item.content.length > 0 &&
              item.content.map((contentLink, i) => (
                <Tooltip
                  key={i}
                  content={
                    <div className="better-links-tooltip">
                      <p>{contentLink.title}</p>
                      <a href={contentLink.url} target="_blank" rel="noreferrer">
                        {contentLink.url}
                        <div className="new-window-icon" />
                      </a>
                    </div>
                  }
                  direction="top"
                >
                  <a
                    className="better-links-tooltip-a"
                    href={contentLink.url}
                    target="_blank"
                    rel="noreferrer"
                  >{`[${i + 1}]`}</a>
                </Tooltip>
              ))}
            <hr />
          </li>
        ))}
      </ol>
    </Layout>
  );
}

export default BetterPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

