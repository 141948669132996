/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./tooltip.css";

const Tooltip = props => {
  const [active, setActive] = useState(false);
  const [tooltipMouseEnter, setTooltipMouseEnter] = useState(false);

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setTimeout(() => {
      setActive(false);
    }, props.delay || 400);
  };
  const showTipMouseEnter = () => {
    setTooltipMouseEnter(true);
  };

  const hideTipMouseEnter = () => {
    setTimeout(() => {
      setTooltipMouseEnter(false);
    }, props.delay || 400);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {(active || tooltipMouseEnter) && (
        <div
          className={`Tooltip-Tip Tooltip-Tip-${props.direction || "top"}`}
          onMouseEnter={showTipMouseEnter}
          onMouseLeave={hideTipMouseEnter}
        >
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;

